import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
var moment = require('moment');

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stats: {},
            option: "page_loads",
            chart_options: {
                tooltips: {
                    mode: 'label'
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10
                            },
                            display: true
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: true
                        }
                    ]
                },
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                maintainAspectRatio: false,
            }
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user ? user : {}
            });
            this.functions.getDashboard();
        },
        getDashboard: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.dashboard();
                await this.promisedSetState({
                    stats: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        }
    };

    calls = {
        dashboard: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.proptree + "/dashboardProptree";
            if (window.location.origin.indexOf('adbooster') !== -1 || window.location.origin.indexOf('localhost') !== -1) {
                url = apiRegister.url.api + "/proptree/getDashboard";
            }
            return apiRegister.call(options, url);
        }
    };

    renders = {
        ctr: () => {
            let ctr = 0;
            try {
                let clicks = this.state.stats.clicks.this_month_total;
                let impressions = this.state.stats.page_loads.this_month_total;
                let ctr = (clicks / impressions) * 100;
                ctr = ctr.toFixed(2);
            } catch (error) { }
            return ctr;
        },
        chartColor: (option) => {
            let options = {
                "page_loads": "26, 213, 189",
                "clicks": "69, 63, 241",
                "leads": "234, 112, 30"
            };
            return options[option];
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="grid grid-cols-12 sm:row-gap-10 row-gap-6 sm:col-gap-10 h-full min-h-full sm:p-0 p-6 pt-0">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/* STATS */}
                {
                    !this.state.loading &&
                    this.state.stats &&
                    <div className="col-span-12 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div className="grid grid-cols-4 gap-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Activity</h3>
                                <div className="relative overflow-hidden rounded-lg bg-white shadow col-span-4">
                                    <div className="grid grid-cols-4">
                                        <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                            <div onClick={() => {
                                                this.setState({
                                                    option: "page_loads"
                                                })
                                            }} className="cursor-pointer">
                                                <dt className="flex flex-row items-center"><div className="w-2 h-2 rounded-full bg-green-500 mr-2"></div><p className="text-sm font-medium text-gray-900">{"Views"}</p></dt>
                                                <dd className="flex items-start">
                                                    <p className={"text-2xl font-semibold"}>{this.state.stats.page_loads ? this.state.stats.page_loads.this_month_total : '-'}</p>
                                                    <div className="flex flex-1"></div>
                                                </dd>
                                            </div>
                                        </div>
                                        <div className="col-span-1 h-full flex flex-col items-center  justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                            <div onClick={() => {
                                                this.setState({
                                                    option: "clicks"
                                                })
                                            }} className="cursor-pointer">
                                                <dt className="flex flex-row items-center"><div className="w-2 h-2 rounded-full bg-purple-500 mr-2"></div><p className="text-sm font-medium text-gray-900">{"Clicks"}</p></dt>
                                                <dd className="flex items-start">
                                                    <p className={"text-2xl font-semibold"}>{this.state.stats.clicks ? this.state.stats.clicks.this_month_total : '-'}</p>
                                                    <div className="flex flex-1"></div>
                                                </dd>
                                            </div>
                                        </div>
                                        <div className="col-span-1 h-full flex flex-col items-center  justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                            <div>
                                                <dt className="flex flex-row items-center"><div className="w-2 h-2 rounded-full bg-yellow-500 mr-2"></div><p className="text-sm font-medium text-gray-900">{"Avg. CTR"}</p></dt>
                                                <dd className="flex items-start">
                                                    <p className={" text-2xl font-semibold"}>{this.renders.ctr()}%</p>
                                                    <div className="flex flex-1"></div>
                                                </dd>
                                            </div>
                                        </div>
                                        <div className="col-span-1 h-full flex flex-col items-center  justify-center px-4 pt-5 pb-6 sm:px-5 sm:pt-6">
                                            <div>
                                                <dt className="flex flex-row items-center"><div className="w-2 h-2 rounded-full bg-orange-500 mr-2"></div><p className="text-sm font-medium text-gray-900">{"Leads"}</p></dt>
                                                <dd className="flex items-start">
                                                    <p className={" text-2xl font-semibold"}>{this.state.stats.leads ? this.state.stats.leads.this_month_total : '-'}</p>
                                                    <div className="flex flex-1"></div>
                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.stats[this.state.option] &&
                                        Array.isArray(this.state.stats[this.state.option]["this_month_daily"]) &&
                                        <div className="w-full h-72 border-t relative p-5">
                                            <Line
                                                options={this.state.chart_options}
                                                data={{
                                                    labels: this.state.stats[this.state.option]["this_month_daily"].map((item) => { return item.date }),
                                                    datasets: [
                                                        {
                                                            borderWidth: 2,
                                                            label: 'Leads',
                                                            borderColor: 'rgb(' + this.renders.chartColor("leads") + ')',
                                                            backgroundColor: 'rgba(' + this.renders.chartColor("leads") + ', 0.3)',
                                                            data: this.state.stats["leads"]["this_month_daily"].map((item) => { return item.value })
                                                        },
                                                        {
                                                            borderWidth: 2,
                                                            label: 'Clicks',
                                                            borderColor: 'rgb(' + this.renders.chartColor("clicks") + ')',
                                                            backgroundColor: 'rgba(' + this.renders.chartColor("clicks") + ', 0.3)',
                                                            data: this.state.stats["clicks"]["this_month_daily"].map((item) => { return item.value })
                                                        },
                                                        {
                                                            borderWidth: 2,
                                                            label: 'Views',
                                                            borderColor: 'rgb(' + this.renders.chartColor("page_loads") + ')',
                                                            backgroundColor: 'rgba(' + this.renders.chartColor("page_loads") + ', 0.3)',
                                                            data: this.state.stats["page_loads"]["this_month_daily"].map((item) => { return item.value })
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="max-w-140 w-full mt-10">
                            <div className="grid grid-cols-4 gap-4">
                                <h3 className="text-lg font-medium leading-6 text-gray-900 col-span-4">Top 10 listings</h3>
                                <div className="relative overflow-hidden rounded-lg bg-white shadow col-span-4">
                                    {
                                        this.state.stats.listings &&
                                        Array.isArray(this.state.stats.listings.clicks) &&
                                        this.state.stats.listings.clicks.map((item, index) => {
                                            return (
                                                <div className={(index > 0 ? "border-t" : "") + " w-full grid grid-cols-12 h-16"}>
                                                    <div className="col-span-4 flex items-center justify-start pl-5">
                                                        {
                                                            item.images.length > 0 &&
                                                            <div style={{ backgroundImage: "url(" + item.images[0] + ")" }} className="h-10 w-10 bg-cover rounded-full border"></div>
                                                        }
                                                        <div className="pl-3">
                                                            <div className="text-sm font-medium">
                                                                {item.id}
                                                            </div>
                                                            <div className="text-xs text-gray-500">
                                                                {123}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-4 flex flex-col items-center justify-center">
                                                        <div className="text-sm font-medium">{item.clicks ? item.clicks : "0"}</div>
                                                        <div className="text-xs text-gray-500">clicks</div>
                                                    </div>
                                                    <div className="col-span-4 flex flex-col items-center justify-center">
                                                        <div className="text-sm font-medium">{item.leads ? item.leads : "0"}</div>
                                                        <div className="text-xs text-gray-500">leads</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="h-12 w-full"></div>
                }

            </div>
        )
    }
}

export default Dashboard;
